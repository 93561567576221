//トップページ　キービジュアルスライダー
import slick from 'slick-carousel';
import Player from '@vimeo/player';
import * as Vivus from 'vivus';
import 'jquery.cookie';
import 'jquery.easing';
import gsap from "gsap";
import {
  ScrollTrigger,
  ScrollToPlugin
} from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import Lenis from '@studio-freight/lenis';

/** スクロール一時禁止 **/
let scroll_control = function (e) {
  e.preventDefault();
}
export function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.addEventListener("touchmove", scroll_control, {
    passive: false
  });
}
export function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.removeEventListener('touchmove', scroll_control, {
    passive: false
  });
}

export function aboutAexFunc() {
  let aboutAexTop = $('#aboutAexTop').length;

  if (aboutAexTop) {
    let resizecheck = $('#responsibleCheck').width();
    let resizecheck2 = 0;
    var ua = window.navigator.userAgent.toLowerCase();

    var uaFlag = 0;
    if ((ua.indexOf("ipad") > -1) ||
      (ua.indexOf("macintosh") > -1 && "ontouchend" in document) ||
      (ua.indexOf('iPod') > 0) ||
      (ua.indexOf('iPad') > 0) ||
      (ua.indexOf('Android') > 0) ||
      (ua.indexOf('BlackBerry') > 0) ||
      (ua.indexOf('PlayBook') > 0) ||
      (ua.indexOf('Kindle') > 0) ||
      (ua.indexOf('Silk') > 0) ||
      (ua.indexOf('Windows Phone') > 0) ||
      (ua.indexOf('Nokia') > 0)
    ) {
      // スマホ・タブレット時の処理
      uaFlag = 1;
    } else {
      // PC時の処理
      uaFlag = 3;
    }

    //背景色の変更
    var secArr = new Array();
    var secArr2 = new Array();
    var current = -1;
    var current2 = -1;
    /* ここに背景色を順に記述する*/
    var secColor = new Array('rgba(0,0,0,0)','#151515');
    var secColor2 = new Array('#023e51', '#0f3c43', '#16351a', '#4a1d24', '#13344b', '#261d3b', '#65691c', '#000');

    function chengeBG(secNum) {
      if (secNum != current) {
          current = secNum;
          $('.animeBack').css({
              background: secColor[current]
          });
      }
    }
    function chengeBG2(secNum) {
      if (secNum != current2) {
          current2 = secNum;
          $('.animeBack2').css({
              background: secColor2[current2]
          });
      }
    }

    function aboutDeptAnimeFunc(){
      let winHight, st, scroll,startPos,endPos,imgH,imgBoxH,imgHSE,num,num2,num3;
      $('.aboutDept').each(function (i) {
        winHight = window.innerHeight;
        scroll = $(window).scrollTop();
        startPos = $(this).offset().top;
        endPos = startPos + $(this).outerHeight() - winHight + 300;
        
        imgBoxH = $(this).find('.mainImg').outerHeight();
        imgH = $(this).find('.mainImg img').outerHeight();
        imgHSE = imgH * 1.1 - imgBoxH;
        
        num = -(1 - (startPos - scroll) / winHight) * 220;
        num2 = -(1 - (startPos - scroll) / winHight) * 190;
        num3 = (startPos - scroll) / 15;

        if (scroll > startPos - winHight + 300 && scroll < endPos) {
          $(this).find('.backIconAnime').css({
            "transform": "translateY(" + num + "px) translateZ(0)",
          });
          $(this).find('.aboutDeptDetailAnime').css({
            "transform": "translateY(" + num2 + "px) translateZ(0)",
          });
          // $(this).find('.mainImg .img').css({
          //   "transform": "translateY(" + num3 + "px) translateZ(0)",
          // });
          // $(this).find('.mainAnime').css({
          //   "transform": "translateY(" + num3 + "%) translateZ(0)",
          // });
        }
      });
    }
    aboutDeptAnimeFunc();

    function aboutDeptImgAnimeFunc(){
      let parallax, winHight, scroll,startPos,endPos;
      $('.aboutDept').each(function (i) {
        parallax = $(this).find('.mainImg');
        winHight = window.innerHeight;
        scroll = $(window).scrollTop();
        startPos = $(this).offset().top;
        endPos = startPos + $(this).outerHeight() - winHight + 300;

        if (scroll > startPos - winHight + 300 && scroll < endPos) {
          parallax.css({
            "background-position-y": ((scroll - startPos) / 15)
          });
        }
      });
    }

    function sideNavMoveFunc(){
      let headerHeight = $("#header").innerHeight();
      let mainHeight = $(".aboutAexArea").innerHeight();
      let sideInnerPos = $(".aboutAexAreaNav ul").position().top / 2;
      let sideInnerHeight = $(".aboutAexAreaNav ul").innerHeight() + sideInnerPos;
      let winHight = window.innerHeight - headerHeight;

      if(sideInnerHeight >= winHight){
        let y = sideInnerHeight - winHight * .7;
        let bStart = $(".aboutAexArea").offset().top;
        let bStop = $(".aboutAexArea").offset().top + mainHeight;
        let bsy = bStop - bStart;
        let ba = y / bsy;

        $(window).on('scroll', function () {
          let start = $(".aboutAexArea").offset().top;
          let stop = $(".aboutAexArea").offset().top + mainHeight;
          let scroll = $(window).scrollTop();
          let sy = scroll - start;
          let transform = sy * ba;
          if(scroll > start && scroll < stop){
            $(".aboutAexAreaNav ul").css({
              "transform":`translateY(-${transform}px)`
            });
          }
        });
      }
    }
    sideNavMoveFunc();

    function sideNavFunc(){
      document.querySelectorAll(".aboutAexAreaNav .nav a").forEach((el) => {
        let id = el.href.split('#')[1];
        let winHight = window.innerHeight;
        let scroll = $(window).scrollTop();
        let startPos = $('#' + id).offset().top;
        let startPosH = $('#' + id).outerHeight();
        let endPos = startPos + $('#' + id).outerHeight() - winHight + 300;
        let flag;

        if (scroll > startPos - winHight + 300 && scroll < endPos) {
          el.classList.add("active");
        } else{
          el.classList.remove("active");
        }
      });
    }
    sideNavFunc();

    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      let winHight = window.innerHeight;
    
      $('.gradation').each(function (i) {
          secArr[i] = $(this).offset().top;
      });
      $('.gradation2').each(function (i) {
          secArr2[i] = $(this).offset().top;
      });

      for (var i = secArr.length - 1; i >= 0; i--) {
        if (scroll > secArr[i] - winHight) {
          chengeBG(i);
          break;
        }
      }
      for (var i = secArr2.length - 1; i >= 0; i--) {
        if (scroll > secArr2[i] - winHight + 200) {
          chengeBG2(i);
          break;
        }
      }

      aboutDeptAnimeFunc();
      aboutDeptImgAnimeFunc();
      sideNavFunc();
    });

    if (resizecheck == '2' && resizecheck != resizecheck2 && uaFlag == 1) { //SPの時のみ
      resizecheck2 = 2;

    } else if (resizecheck == '1' && resizecheck != resizecheck2 && uaFlag == 3) { //PCの時のみ

      let ignitionTimeoutID;
      let isWheel = true;
      let isTrackPad = false;

      document.addEventListener('mousemove', event => {
        let imgBox, txtBox;
        //X座標(値は適宜調整)
        var x = Math.round(event.pageX / 50);
        //Y座標(値は適宜調整)
        var y = Math.round(event.screenY / 50);

        $('.aboutDept').each(function (i) {
          imgBox = $(this).find('.mainImg');
          txtBox = $(this).find('.txtBox');

            imgBox.css({
              "transform": "translateX(" + -x + "px) translateY(" + -y + "px) translateZ(0)",
            });
            txtBox.css({
              "transform": "translateX(" + -x + "px) translateY(" + -y + "px) translateZ(0)",
            });
        });
      });
      resizecheck2 = 1;
    }
  }
}

export function aboutAexLowerFunc() {
  let aboutAexLower = $('#aboutAexLower').length;

  if (aboutAexLower) {
    $('.fvSlider').slick({
      loop: true,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 5000,
      dots: false,
      arrows: false,
      fade: true,
      infinite: true,
    });
  }
}