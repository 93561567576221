//トップページ　キービジュアルスライダー
import Player from '@vimeo/player';
import * as Vivus from 'vivus';
import 'jquery.cookie';
import 'jquery.easing';
import gsap from "gsap";
import {
  ScrollTrigger,
  ScrollToPlugin
} from "gsap/all";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import Lenis from '@studio-freight/lenis'

/** スクロール一時禁止 **/
let scroll_control = function (e) {
  e.preventDefault();
}
export function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.addEventListener("touchmove", scroll_control, {
    passive: false
  });
}
export function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.removeEventListener('touchmove', scroll_control, {
    passive: false
  });
}

export function idxFunc() {
  let indexTop = $('#indexTop').length;

  if (indexTop) {
    let resizecheck = $('#responsibleCheck').width();
    let resizecheck2 = 0;
    var ua = window.navigator.userAgent.toLowerCase();

    var uaFlag = 0;
    if ((ua.indexOf("ipad") > -1) ||
      (ua.indexOf("macintosh") > -1 && "ontouchend" in document) ||
      (ua.indexOf('iPod') > 0) ||
      (ua.indexOf('iPad') > 0) ||
      (ua.indexOf('Android') > 0) ||
      (ua.indexOf('BlackBerry') > 0) ||
      (ua.indexOf('PlayBook') > 0) ||
      (ua.indexOf('Kindle') > 0) ||
      (ua.indexOf('Silk') > 0) ||
      (ua.indexOf('Windows Phone') > 0) ||
      (ua.indexOf('Nokia') > 0)
    ) {
      // スマホ・タブレット時の処理
      uaFlag = 1;
    } else {
      // PC時の処理
      uaFlag = 3;
    }

    //背景色の変更
    var secArr = new Array();
    var secArr2 = new Array();
    var current = -1;
    var current2 = -1;
    /* ここに背景色を順に記述する*/
    var secColor = new Array('rgba(0,0,0,0)','#151515');
    var secColor2 = new Array('#151515', '#023e51', '#0f3c43', '#16351a', '#4a1d24', '#13344b', '#261d3b', '#65691c', '#151515', '#383838');

    function chengeBG(secNum) {
      if (secNum != current) {
          current = secNum;
          $('.animeBack').css({
              background: secColor[current]
          });
      }
    }
    function chengeBG2(secNum) {
      if (secNum != current2) {
          current2 = secNum;
          $('.animeBack2').css({
              background: secColor2[current2]
          });
      }
    }

    function aboutDeptAnimeFunc(){
      let winHight, st, scroll,startPos,endPos,imgH,imgBoxH,imgHSE,num,num2,num3;
      $('.aboutDept').each(function (i) {
        winHight = window.innerHeight;
        scroll = $(window).scrollTop();
        startPos = $(this).offset().top;
        endPos = startPos + $(this).outerHeight() - winHight + 300;
        
        imgBoxH = $(this).find('.mainImg').outerHeight();
        imgH = $(this).find('.mainImg img').outerHeight();
        imgHSE = imgH * 1.1 - imgBoxH;
        
        num = -(1 - (startPos - scroll) / winHight) * 220;
        num2 = -(1 - (startPos - scroll) / winHight) * 190;
        num3 = (startPos - scroll) / 15;

        if (scroll > startPos - winHight + 300 && scroll < endPos) {
          $(this).find('.backIconAnime').css({
            "transform": "translateY(" + num + "px) translateZ(0)",
          });
          $(this).find('.aboutDeptDetailAnime').css({
            "transform": "translateY(" + num2 + "px) translateZ(0)",
          });
          // $(this).find('.mainImg .img').css({
          //   "transform": "translateY(" + num3 + "px) translateZ(0)",
          // });
          // $(this).find('.mainAnime').css({
          //   "transform": "translateY(" + num3 + "%) translateZ(0)",
          // });
        }
      });
    }
    aboutDeptAnimeFunc();

    function aboutDeptImgAnimeFunc(){
      let parallax, winHight, scroll,startPos,endPos;
      $('.aboutDept').each(function (i) {
        parallax = $(this).find('.mainImg');
        winHight = window.innerHeight;
        scroll = $(window).scrollTop();
        startPos = $(this).offset().top;
        endPos = startPos + $(this).outerHeight() - winHight + 300;

        if (scroll > startPos - winHight + 300 && scroll < endPos) {
          parallax.css({
            "background-position-y": ((scroll - startPos) / 15)
          });
        }
      });
    }

    function aboutDeptScrFunc(){
      let flag = 1;
      let prev_pos = $(window).scrollTop();
      const $boxes = $('.aboutDept');
	    const boxes_cnt = $boxes.length;
      let winHight = window.innerHeight;
      let headerHeight = $('#header').outerHeight(true);

      // スクロールスナップ
      $(window).on('scroll', function () {
        let current_pos = $(this).scrollTop();
        for (let i = 1; i < boxes_cnt; i++) {
          const prev_offset = $('.aboutDept0' + i).offset().top;
          const prev_H = $('.aboutDept0' + i).outerHeight();
          const next_offset = $('.aboutDept0' + (i + 1)).offset().top;

          if ((current_pos > prev_offset) && ( current_pos < next_offset) && (flag === 1)) {
              if (current_pos > prev_pos) {
                  if(current_pos > prev_offset + prev_H - winHight){
                    flag = 2;
                    no_scroll();
                    $('html, body').stop(true).animate({ scrollTop: next_offset}, 1000, 'easeInOutQuart', function(){
                        flag = 1;
                        return_scroll();
                    });
                  }
              } else if (current_pos < prev_pos) {
                if (current_pos > next_offset) {
                  flag = 2;
                  $('html, body').stop(true).animate({ scrollTop: prev_offset}, 1000, 'easeInOutQuart', function(){
                      flag = 1;
                  });
                }
              }
          }
        }
        prev_pos = current_pos;
      });
    }

    function aboutDeptScrFunc02(){
      // let boxes = gsap.utils.toArray(".aboutDept"),
      // container = document.querySelector("#aboutAexArea"),
      // padding = gsap.getProperty(container, "paddingTop", "px"),
      // snapTriggers = boxes.map(box => ScrollTrigger.create({
      //   trigger: box,
      //   start: "top " + padding + "px",
      //   markers: true
      // })),
      // snaps = [];

      // ScrollTrigger.create({
      //   trigger: '#aboutAexArea',
      //   start: "top top",
      //   end: () => "+=" + (boxes[boxes.length-1].getBoundingClientRect().top - boxes[0].getBoundingClientRect().top),
      //   onRefresh: self => {
      //     let distance = self.end - self.start;
      //     snapTriggers.forEach((trigger, i) => snaps[i] = (trigger.start - self.start) / distance);
      //   },
      //   snap: snaps,
      //   markers: true
      // });

      const panels = document.querySelectorAll(".aboutDept");
      const navLinks = document.querySelectorAll("a");
      const headerH = $('#header').height();

      function goToPanel(panel) {
        const tw = gsap.to(window, {
          scrollTo: { 
            y: panel, 
            //offsetY: () => `${headerH}`,
            autoKill: false 
          },
          duration: 1.5,
          ease: "Power4.out",
          invalidateOnRefresh: true,
        });

        navLinks.forEach((btn, index) => {
          btn.addEventListener("click", (e) => {
            tw.invalidate();
          });
        });
      }
      panels.forEach((panel) => {
        ScrollTrigger.create({
          trigger: panel,
          end: "bottom top+=1",
          //markers: 1,
          onEnter: () => goToPanel(panel),
          onEnterBack: () => goToPanel(panel),
        });
      });

      // let sections = gsap.utils.toArray(".aboutDept");
      // const headerH = $('#header').height();
      // gsap.to(sections, {
      //   ease: "none",
      //   scrollTrigger: {
      //     trigger: ".aboutDept01",
      //     endTrigger:".aboutDept07",
      //     snap: 1 / (sections.length),
      //     // snap: {
      //     //   snapTo: 1 / (sections.length - 1),
      //     //   duration: 0.1,
      //     //   delay: 0.1,
      //     //   ease: "power1.inOut"
      //     // },
      //     start: "top " + headerH + "px",
      //     end: "bottom top+=1",
      //     markers: 1,
      //   }
      // });

      // const panels = document.querySelectorAll(".aboutDept");
      // const headerHeight = $('#header').outerHeight(true);
      // let position;
      // panels.forEach((panel, index) => {
      //   ScrollTrigger.create({
      //     trigger: panel,
      //     start: "center top",
      //     end: "bottom-=100 top+=1",
      //     markers: 1,
      //     onEnter: () => {
      //       position = $("#aboutDept0" + (index + 2)).offset().top - headerHeight;
      //       $('body,html').animate({
      //         scrollTop: position
      //       }, 1000, 'easeInOutQuart');
      //       return false;
      //     },
      //     onEnterBack: () => {
      //       position = $("#aboutDept0" + (index + 1)).offset().top - headerHeight;
      //       $('body,html').animate({
      //         scrollTop: position
      //       }, 1000, 'easeInOutQuart');
      //       return false;
      //     },
      //   });
      // });
    }

    function sideNavMoveFunc(){
      let headerHeight = $("#header").innerHeight();
      let mainHeight = $(".aboutAexArea").innerHeight();
      let sideInnerPos = $(".aboutAexAreaNav ul").position().top / 2;
      let sideInnerHeight = $(".aboutAexAreaNav ul").innerHeight() + sideInnerPos;
      let winHight = window.innerHeight - headerHeight;

      if(sideInnerHeight >= winHight){
        let y = sideInnerHeight - winHight * .7;
        let bStart = $(".aboutAexArea").offset().top;
        let bStop = $(".aboutAexArea").offset().top + mainHeight;
        let bsy = bStop - bStart;
        let ba = y / bsy;

        $(window).on('scroll', function () {
          let start = $(".aboutAexArea").offset().top;
          let stop = $(".aboutAexArea").offset().top + mainHeight;
          let scroll = $(window).scrollTop();
          let sy = scroll - start;
          let transform = sy * ba;
          if(scroll > start && scroll < stop){
            $(".aboutAexAreaNav ul").css({
              "transform":`translateY(-${transform}px)`
            });
          }
        });
      }
    }
    sideNavMoveFunc();

    function sideNavFunc(){
      document.querySelectorAll(".aboutAexAreaNav .nav a").forEach((el) => {
        let id = el.href.split('#')[1];
        let winHight = window.innerHeight;
        let scroll = $(window).scrollTop();
        let startPos = $('#' + id).offset().top;
        let startPosH = $('#' + id).outerHeight();
        let endPos = startPos + $('#' + id).outerHeight() - winHight + 300;
        let flag;

        if (scroll > startPos - winHight + 300 && scroll < endPos) {
          el.classList.add("active");
        } else{
          el.classList.remove("active");
        }
      });
    }
    sideNavFunc();

    $(window).on('scroll', function () {
      var scroll = $(window).scrollTop();
      let winHight = window.innerHeight;
    
      $('.gradation').each(function (i) {
          secArr[i] = $(this).offset().top;
      });
      $('.gradation2').each(function (i) {
          secArr2[i] = $(this).offset().top;
      });

      for (var i = secArr.length - 1; i >= 0; i--) {
        if (scroll > secArr[i] - winHight) {
          chengeBG(i);
          break;
        }
      }
      for (var i = secArr2.length - 1; i >= 0; i--) {
        if (scroll > secArr2[i] - winHight + 200) {
          chengeBG2(i);
          break;
        }
      }

      aboutDeptAnimeFunc();
      aboutDeptImgAnimeFunc();
      sideNavFunc();
    });

    if (resizecheck == '2' && resizecheck != resizecheck2 && uaFlag == 1) { //SPの時のみ
      resizecheck2 = 2;

    } else if (resizecheck == '1' && resizecheck != resizecheck2 && uaFlag == 3) { //PCの時のみ

      let ignitionTimeoutID;
      let isWheel = true;
      let isTrackPad = false;
      
      //document.querySelector('body').addEventListener('mousewheel', disableScroll, { passive: false });
      // function disableScroll(e) {
      //   const wheelDeltaY = e.wheelDeltaY * 1.2;
      //   if (isWheel) e.preventDefault();

      //   // スクロールの出だしが10px以下だと1.5秒間トラックパッドと判定する
      //   if (Math.abs(wheelDeltaY) < 10) {
      //     isWheel = false;
      //     isTrackPad = true;

      //     clearTimeout(ignitionTimeoutID);
      //     ignitionTimeoutID = setTimeout(() => {
      //       isTrackPad = false;
      //     }, 1500);
      //   } else {
      //     if (!isTrackPad) {
      //       isWheel = true;

      //       let direction = `-=${Math.abs(wheelDeltaY)}px`;
      //       if (wheelDeltaY < 0) direction = `+=${Math.abs(wheelDeltaY)}px`;
      //       gsap.to(window, {
      //         scrollTo: { y: direction, autoKill: false },
      //         duration: 1.1,
      //         ease: 'power2.out',
      //       });
      //     }
      //   }
      // }

      document.addEventListener('mousemove', event => {
        let imgBox, txtBox;
        //X座標(値は適宜調整)
        var x = Math.round(event.pageX / 50);
        //Y座標(値は適宜調整)
        var y = Math.round(event.screenY / 50);

        $('.aboutDept').each(function (i) {
          imgBox = $(this).find('.mainImg');
          txtBox = $(this).find('.txtBox');

            imgBox.css({
              "transform": "translateX(" + -x + "px) translateY(" + -y + "px) translateZ(0)",
            });
            txtBox.css({
              "transform": "translateX(" + -x + "px) translateY(" + -y + "px) translateZ(0)",
            });
        });
      });
      resizecheck2 = 1;
    }

    // setTimeout(() => {
    //   new Vivus('objAnime', {
    //     duration: 20,
    //     type: 'oneByOne',
    //   });
    // }, 3000);

    // const boxes = document.querySelectorAll(".aboutDept")
    // const options = {
    //   root: null, 
    //   rootMargin: "0px 0px -25% 0px",
    //   threshold: 0 
    // };
    // const observer = new IntersectionObserver(doWhenIntersect, options);
    // boxes.forEach(box => {
    //   observer.observe(box);
    // });

    // /**
    //  * 交差したときに呼び出す関数
    //  * @param entries
    //  */
    // function doWhenIntersect(entries) {
    //   entries.forEach(entry => {
    //   if (entry.isIntersecting) {
    //     activateIndex(entry.target);
    //   }
    //   });
    // }

    // function activateIndex(element) {
    //   const currentActiveIndex = document.querySelector(".aboutAexAreaNav .active");
    //   if (currentActiveIndex !== null) {
    //     currentActiveIndex.classList.remove("active");
    //   }
    //   const newActiveIndex = document.querySelector(
    //     `a[href='#${element.id}']`
    //   );
    //   newActiveIndex.classList.add("active");
    // }
  }
}

export function movieSwitchFunc() {
  let indexTop = $('#indexTop').length;

  if (indexTop) {
    let resizecheck = $('#responsibleCheck').width();
    let resizecheck2 = 0;
    let movieId,modalMovieId;

    if (resizecheck == '2' && resizecheck != resizecheck2) { //SPの時のみ
      movieId = 870988168;
      modalMovieId = 870988168;
      resizecheck2 = 2;
    } else if (resizecheck == '1' && resizecheck != resizecheck2) {
      movieId = 870988168;
      modalMovieId = 870988168;
      resizecheck2 = 1;
    }

    var mainOptionsSwitch = {
      id: movieId, // VimeoのID
      background: 1,
      autoplay: 0,
      muted: true,
      autopause: true
    };

    var mainModaleOptionsSwitch = {
      id: modalMovieId, // VimeoのID
      controls: true,
      autoplay: 1,
      muted: true,
      autopause: true,
      loop: false
    };

    var mainPlayerSwitch = new Player('mainMovie', mainOptionsSwitch);
    //var v = document.getElementById('mainviewVideo');

    // if($.cookie("aexMainviewAnime") == undefined){
    //   //var mainModalPlayerSwitch = new Player('modalVimeo', mainModaleOptionsSwitch);
    //   //mainPlayerSwitch.pause();
    //   v.pause();
    // }
  }
}

export function mainMovieFunc() {
  let indexTop = $('#indexTop').length;

  if (indexTop) {
    let resizecheck = $('#responsibleCheck').width();
    let resizecheck2 = 0;
    var ua = window.navigator.userAgent.toLowerCase();

    var uaFlag = 0;
    if ((ua.indexOf("ipad") > -1) ||
      (ua.indexOf("macintosh") > -1 && "ontouchend" in document) ||
      (ua.indexOf('iPod') > 0) ||
      (ua.indexOf('iPad') > 0) ||
      (ua.indexOf('Android') > 0) ||
      (ua.indexOf('BlackBerry') > 0) ||
      (ua.indexOf('PlayBook') > 0) ||
      (ua.indexOf('Kindle') > 0) ||
      (ua.indexOf('Silk') > 0) ||
      (ua.indexOf('Windows Phone') > 0) ||
      (ua.indexOf('Nokia') > 0)
    ) {
      // スマホ・タブレット時の処理
      uaFlag = 1;
    } else {
      // PC時の処理
      uaFlag = 3;
    }

    let easeOutQuart = t => 1 - Math.pow(1 - t, 4);
    let lenis = new Lenis({
      lerp: 0.2, // 慣性の強さ
      duration: 1, // スクロールアニメーションの時間
      easing: easeOutQuart, // イージング関数
    });
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    var mainPlayer = new Player('mainMovie');
    //var v = document.getElementById('mainviewVideo');

    lenis.start();

    setTimeout(function(){
      mainPlayer.play();
      //v.play();
    },100);

    var playerObj;
    playerObj = mainPlayer.play();
    //playerObj = v.play();
    if (playerObj) {
      playerObj.then(() => {
        setTimeout(() => {
          playerObj
        }, playerObj.duration * 100);
      }).catch((e) => {
      });
    }

    // if($.cookie("aexMainviewAnime") == "aexMainviewAnime"){

    //   lenis.start();

    //   setTimeout(function(){
    //     //mainPlayer.play();
    //     v.play();
    //   },100);
  
    //   var playerObj;
    //   //playerObj = mainPlayer.play();
    //   playerObj = v.play();
    //   if (playerObj) {
    //     playerObj.then(() => {
    //       setTimeout(() => {
    //         playerObj
    //       }, playerObj.duration * 100);
    //     }).catch((e) => {
    //     });
    //   }
    // }
    
    // if($.cookie("aexMainviewAnime") == undefined){
    //   var mainModalPlayer = new Player('modalVimeo');

    //   lenis.stop();
    //   no_scroll();
    //   $(".overlay").fadeIn();
    //   mainModalPlayer.play();
    //   //mainPlayer.pause();
    //   v.pause();

    //   $(".modalBack").on('click', function () {
    //     lenis.start();
    //     return_scroll();
    //     $(".overlay").fadeOut();
    //     mainModalPlayer.pause();
    //     mainModalPlayer.unload();
    //     //mainPlayer.play();
    //     v.play();
    //     $.cookie("aexMainviewAnime", "aexMainviewAnime");
    //   });
    
    //   $('.modalMovieClose').on('click', function () {
    //     lenis.start();
    //     return_scroll();
    //     $(".overlay").fadeOut();
    //     mainModalPlayer.pause();
    //     mainModalPlayer.unload();
    //     //mainPlayer.play();
    //     v.play();
    //     $.cookie("aexMainviewAnime", "aexMainviewAnime");
    //   });
    // }

    // $.cookie('aexMainviewAnime', 'aexMainviewAnime', {
    //   path: '/',
    //   domain:'.aexinc.co.jp/'
    // });
  }
}